section.case-studies {
  .slide {
    width: 400px;
    height: initial;
    padding: 30px;
    border-radius: 80px;
    background: #fff;
    box-sizing: border-box;
    transition: all 0.3s linear;

    h2,
    h3,
    p,
    .heading,
    .channels span {
      color: #000;
    }

    .heading {
      @include fluid-type($min-screen-width, $max-screen-width, 30px, 46px);
      text-transform: uppercase;
      border-top: 4px solid #2e3337;
      border-bottom: 4px solid #2e3337;
      font-weight: 900;
      text-align: center;
      padding: 0.05em;
    }

    .industry,
    .subtitle {
      text-align: center;
      text-transform: uppercase;
    }

    .industry {
      margin-bottom: 0;
    }

    .subtitle {
      margin-top: 0;
      font-weight: 600;
      margin-bottom: 1.2rem;
    }

    p {
      margin-top: 0;
      margin-bottom: 0.2em;
      @include fluid-type($min-screen-width, $max-screen-width, 12px, 20px);

      .name {
        font-weight: 600;
      }
    }

    .yellow {
      text-shadow: 1px 1px rgb(48, 48, 48);
    }

    .channels {
      h3 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
        @include fluid-type($min-screen-width, $max-screen-width, 17px, 34px);
      }

      .list {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 0.5rem 0.8rem;

          img {
            width: 4rem;
            margin-bottom: 0.5rem;
            height: auto;
          }

          span {
            @include fluid-type(
              $min-screen-width,
              $max-screen-width,
              9px,
              13px
            );
            text-transform: uppercase;
          }
        }
      }
    }

    @media only screen and (min-width: 1000px) {
      &.swiper-slide-active {
        transform: scale(1.2);
        z-index: 1;
        box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.3);
      }
    }

    @media only screen and (max-width: 500px) {
      width: 100% !important;
    }
  }

  .swiper-wrapper {
    padding: 7% 0px;
  }
}
