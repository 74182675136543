section.pricing {
  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex-basis: 25%;
    box-sizing: border-box;

    h3,
    p {
      @include fluid-type($min-screen-width, $max-screen-width, 12px, 20px);
      text-align: center;
    }

    .heading {
      border-bottom: 1px solid $yellow;

      .title {
        margin-bottom: 0.2em;
      }

      .subtitle {
        font-weight: 300;
        margin-top: 0;
      }
    }

    p {
      font-weight: 600;

      span {
        font-weight: 300;
        display: block;
      }
    }

    @media only screen and (min-width: $mobileWidth) {
      &:not(:last-of-type) {
        border-right: 1px solid #fff;
      }
    }

    @media only screen and (max-width: $mobileWidth) {
      flex-basis: 100%;
      margin-bottom: 1em;

      &:not(:last-of-type) {
        border-bottom: 1px solid $yellow;
      }

      .heading {
        border-bottom: 0;

        h3 {
          @include fluid-type($min-screen-width, $max-screen-width, 14px, 30px);
        }
      }
    }
  }
}
