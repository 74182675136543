$whatWeDoSpacing: 2rem;
$fieldsSpacing: 1rem;

section.what-we-do {
  @media only screen and (max-width: $mobileWidth) {
    margin-top: 3rem;
  }

  .wrapper {
    @media only screen and (min-width: $mobileWidth) {
      display: flex;
      justify-content: space-between;
    }

    .list {
      flex-basis: 400px;

      @media only screen and (max-width: $mobileWidth) {
        margin-bottom: $elementsSpacing;
      }

      .item {
        display: flex;
        align-items: center;
        margin-bottom: $whatWeDoSpacing;

        h2 {
          font-weight: 400;
        }

        img {
          width: 4rem;
          height: auto;
          margin-right: $whatWeDoSpacing;
        }
      }
    }
  }
}

.form {
  flex-basis: 650px;

  .title {
    font-weight: 400;
    margin-top: 0;
  }

  input,
  textarea {
    border: 1px solid #808080;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 20px;
    background: transparent;
    color: #6c6c6c;
    font-weight: 400;
    box-sizing: border-box;
  }

  textarea {
    resize: none;
  }

  .consent-container {
    display: flex;
    align-items: flex-start;
  }

  input[type="checkbox"] {
    width: 15px;
    margin-top: 0;
    margin-right: 10px;
  }

  label.consent {
    width: calc(100% - 25px);
    font-size: 10px;
    color: #6c6c6c;
    a {
      color: #6c6c6c;
    }
  }

  input,
  textarea,
  .btn {
    @include fluid-type($min-screen-width, $max-screen-width, 18px, 27px);
  }

  .btn {
    margin-top: 10px;
    float: right;
    padding: 5px 35px;
  }
}
