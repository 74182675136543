$wrapperWidth: 1200px;
$yellow: #eee22b;
$mobileWidth: 767px;
$elementsSpacing: 6rem;

//Swiper & its module styles
$themeColor: #fff;
@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/swiper/modules/navigation/navigation.scss";

//Section class is identical with file name
@import "partials/typography";
@import "partials/header";
@import "partials/what-we-do";
@import "partials/who-do-we-work-with";
@import "partials/case-studies";
@import "partials/cutscene";
@import "partials/opinions";
@import "partials/pricing";
@import "partials/channels";
@import "partials/trusted-us";
@import "partials/cooperation";
@import "partials/why-we";
@import "partials/book-appointment";
@import "partials/consent";
@import "partials/privacy-policy";

body {
  background: #000000;
  padding: 0;
  margin: 0;
}

.wrapper {
  max-width: $wrapperWidth;
  margin: 0 auto;

  @media only screen and (max-width: 1220px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.has-carousel {
  position: relative;

  @media only screen and (min-width: $mobileWidth) {
    .swiper-button-next {
      right: -3rem !important;
    }

    .swiper-button-prev {
      left: -3rem;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    padding-left: 20px;
    padding-right: 20px;

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 20px;
    }

    .swiper-button-next {
      right: -10px;
    }

    .swiper-button-prev {
      left: -10px;
    }
  }
}

section {
  margin: $elementsSpacing 0;

  @media only screen and (max-width: $mobileWidth) {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.btn {
  background: $yellow;
  padding: 5px 10px;
  border: 1px solid #d9d244;
  color: #010100;
  transition: all 0.3s ease;

  &:hover {
    background: transparent;
    color: $yellow !important;
    cursor: pointer;
  }
}

.yellow {
  color: $yellow;
}

*,
html {
  scroll-behavior: smooth !important;
}
