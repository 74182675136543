header.header {
  position: relative;
  overflow: hidden;
  background: #212121;

  & > img {
    width: 100%;
    height: auto;
  }

  .navbar {
    margin-top: 1rem;

    @media only screen and (max-width: $mobileWidth) {
      margin-top: 10px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      z-index: 1;

      img {
        width: 3rem;
        height: auto;
      }
    }

    nav {
      display: flex;
      align-items: center;
      position: relative;
      padding: 20px 0;
      justify-content: space-between;
      z-index: 1;

      a {
        position: relative;
        @include fluid-type($min-screen-width, $max-screen-width, 16px, 22px);
        color: #dbdbd9;
        text-decoration: none;
        margin-left: 6rem;

        @media only screen and (max-width: 1000px) {
          margin-left: 3rem;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 0;
          left: 0;
          right: 0;
          top: 5px;
          transition: all 0.3s ease;
          background: $yellow;
        }

        &:hover:after {
          width: 100%;
          cursor: pointer;
        }

        &.btn {
          color: #010100;

          &:after {
            display: none;
          }
        }
      }

      @media only screen and (max-width: $mobileWidth) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateY(-100%);
        background: #000;
        flex-direction: column;
        justify-content: center;
        padding-top: 2rem;
        z-index: 9999;
        transition: all 0.3s ease;

        a {
          margin-left: 0;
          margin: 1rem 0;
        }

        &.visible {
          transform: translateY(0);
        }
      }
    }
  }

  .menu-btn {
    @media only screen and (max-width: $mobileWidth) {
      z-index: 9999;

      img {
        width: 25px;
        height: auto;
      }
    }

    @media only screen and (min-width: $mobileWidth) {
      display: none;
    }
  }

  .header-image-top {
    margin-top: -122px;

    @media only screen and (max-width: $mobileWidth) {
      margin-top: -86px;
    }
  }

  h1 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em 0;
    @include fluid-type($min-screen-width, $max-screen-width, 14px, 54px);

    bold {
      display: block;
      @include fluid-type($min-screen-width, $max-screen-width, 18px, 70px);
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: -10%;
    left: -10%;
  }

  &:before {
    top: 0;
    box-shadow: 0 0 120px 80px #000;

    @media only screen and (max-width: $mobileWidth) {
      box-shadow: 0 0 50px 30px #000;
    }
  }

  &:after {
    bottom: 0;
    box-shadow: 0 0 50px 100px #000;

    @media only screen and (max-width: $mobileWidth) {
      box-shadow: 0 0 30px 50px #000;
    }
  }
}
