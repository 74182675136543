footer.book-appointment {
  margin-bottom: 2rem;

  .wrapper {
    display: flex;
    max-width: 950px;

    .image-container {
      img {
        width: 85%;
        margin-left: 15%;
      }

      @media only screen and (max-width: $mobileWidth) {
        display: none;
      }
    }
  }
}
