.user-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 3px solid $yellow;
  padding: 1.2rem 0;
  background: #000;
  z-index: 99999;
  transition: all 0.3s ease;
  box-sizing: border-box;
  transform: translateY(105%);

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 300;
      margin: 0;
      @include fluid-type($min-screen-width, $max-screen-width, 10px, 19px);
    }

    .btn {
      margin-left: 2rem;
    }
  }

  &.visible {
    transform: translateY(0);
  }
}
