section.privacy-policy {
  h2 {
    font-weight: 400;
    margin-top: 3rem;
  }

  p {
    font-weight: 300;

    a {
      color: #fff;
    }
  }
}
