section.who-do-we-work-with {
  h1 {
    @include fluid-type($min-screen-width, $max-screen-width, 18px, 35px);
    font-weight: 400;
  }

  @media only screen and (min-width: $mobileWidth) {
    margin-left: -5rem;
    h1 {
      padding-right: 7.5em;
    }
  }

  p {
    margin: 0.15em;
    @include fluid-type($min-screen-width, $max-screen-width, 13px, 21px);

    .big {
      @include fluid-type($min-screen-width, $max-screen-width, 18px, 45px);
      margin: 0 0.2em;
      font-weight: 900;
    }

    @media only screen and (max-width: $mobileWidth) {
      text-align: center;
    }

    @media only screen and (min-width: $mobileWidth) {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-of-type(1) {
        padding-left: 2em;
      }

      &:nth-of-type(2) {
        padding-left: 14.6em;
      }

      &:nth-of-type(3) {
        padding-left: 19.5em;
      }
    }
  }
}
