section.cutscene {
  p {
    @include fluid-type($min-screen-width, $max-screen-width, 18px, 46px);
    font-weight: 900;
    margin-bottom: 0.1em;
    margin-top: 0;
    text-align: center;

    &:nth-of-type(2) {
      padding-right: 0.65em;
    }

    &:nth-of-type(3) {
      padding-left: 0.3em;
    }
  }

  .logo {
    width: 11em;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-top: 0.6rem;
  }
}
