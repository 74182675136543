section.cooperation {
  .list {
    max-width: 1000px;
    margin: 0 auto;

    .item {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      margin: 0.2em 0;

      .arrow {
        margin-left: 0.3em;
        width: 16px;
        height: auto;

        &.left {
          transform: rotate(180deg);
          margin-left: 0;
          margin-right: 0.3em;
        }

        @media only screen and (max-width: $mobileWidth) {
          display: none;
        }
      }

      div {
        @include fluid-type($min-screen-width, $max-screen-width, 9px, 20px);
      }

      & > div:first-of-type,
      & > div:last-of-type {
        font-weight: 300;
        display: flex;
        align-items: center;
      }

      & > div:first-of-type {
        text-align: right;
        padding-right: 2rem;
        justify-content: flex-end;
      }

      & > div:nth-of-type(2) {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        span {
          @include fluid-type($min-screen-width, $max-screen-width, 17px, 45px);
          margin-right: 0.2em;
          font-weight: 600;
        }

        h2 {
          margin: 0;
          @include fluid-type($min-screen-width, $max-screen-width, 13px, 30px);
        }
      }

      & > div:last-of-type {
        text-align: left;
        padding-left: 2rem;
      }
    }
  }
}
