section.channels {
  .list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      margin: 1.7rem;

      @media only screen and (max-width: $mobileWidth) {
        margin: 1.3rem;
      }

      img {
        width: 4rem;
        height: auto;
      }

      span {
        display: block;
        text-align: center;
        @include fluid-type($min-screen-width, $max-screen-width, 10px, 15px);
      }
    }
  }
}
