section.trusted-us {
  .slide {
    display: inline-block;
    width: 9.5rem;

    img {
      height: 8rem;
      width: auto;
      margin: 0 1.5rem;
      filter: grayscale(1);
    }

    @media only screen and (max-width: $mobileWidth) {
      width: calc(50% - 15px); //30px elements spacing

      img {
        width: 100%;
        margin: 0;
        height: auto;
      }
    }
  }
}
